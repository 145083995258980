/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { Component } from "react";
import PropTypes from "prop-types";
import fetchWP from "../utils/fetchWP";

export default class Widget extends Component {
  state = {
    fairs: null,
    searchInput: "",
    isLoading: false,
    openOverlay: false,
    showLargeSearch: {
      display: "none"
    }
  };

  fetchWP = new fetchWP({
    restURL: this.props.wpObject.api_url,
    restNonce: this.props.wpObject.api_nonce
  });

  componentDidMount() {
    const topbar = document.querySelector(".topbar .email");
    const searchWidgetEl = document.createElement("div");
    searchWidgetEl.innerHTML =
      '<div class="search-fair-widget">Znajdź targi już teraz <img class="search-icon" style="max-width:24px" src="https://targiwniemczech.pl/images/icon-search.png"></div>';
    searchWidgetEl.addEventListener("click", this.openSearch);
    topbar.appendChild(searchWidgetEl);
  }

  handleChangeSearchInput = e => {
    this.setState({ [e.target.name]: e.target.value.toLowerCase() });
    if (this.state.searchInput !== "") {
      this.setState({
        showLargeSearch: { display: "block" }
      });
    }
  };

  openSearch = () => {
    this.setState({ isLoading: true, openOverlay: true });
    if (!this.state.fairs) {
      this.fetchWP
        .get("fairs")
        .then(json =>
          this.setState({
            fairs: json.value,
            isLoading: false
          })
        )
        .catch(err => console.log(err));
    } else {
      this.setState({
        isLoading: false
      });
    }
  };

  closeSearch = () => {
    this.setState({ openOverlay: false });
  };

  render() {
    const { searchInput, fairs, isLoading } = this.state;
    let fairsList = [];

    if (fairs) {
      fairsList = fairs
        .filter(fair =>
          fair.post_title
            .toLowerCase()
            .includes(searchInput.replace("targi", ""))
        )
        .map(fair => (
          <div key={fair.ID} className="row search__overlay__content__results">
            <div>
              <div className="col-12 pl-0">
                <h4 className="search__overlay__content__results__element">
                  <a
                    className="search__overlay__content__results__element__link"
                    href={`${fair.post_name}.html`}
                  >
                    {fair.post_title}
                  </a>
                </h4>
              </div>
            </div>
          </div>
        ));
    }
    return (
      <>
        <div className="search">
          <input
            className="search__input pl-0"
            type="text"
            placeholder='Wpisz czego szukasz (np. "targi Kolonia")'
            onClick={this.openSearch}
          />
          {this.state.openOverlay ? (
            <div className="search__overlay">
              <span
                className="search__overlay__btn"
                onClick={this.closeSearch}
                title="Zamknij wyszukiwarkę"
              >
                <i className="fas fa-times" />
              </span>
              <div className="search__overlay__content">
                {!isLoading ? (
                  <>
                    <div className="row">
                      <form className="search__overlay__content__form">
                        <input
                          className="search__overlay__content__form__input"
                          autoFocus={true}
                          type="text"
                          name="searchInput"
                          value={this.state.searchInput}
                          onChange={this.handleChangeSearchInput}
                          placeholder="Wpisz tutaj jakich targów szukasz..."
                        />
                      </form>
                    </div>
                    {searchInput !== "" ? fairsList : null}
                  </>
                ) : (
                  <>
                    <h2>Trwa ładowanie...</h2>
                  </>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

Widget.propTypes = {
  wpObject: PropTypes.object
};
